<template>
  <div class="mx-4 my-4 md:mx-8">
    <h1 class="text-3xl mb-5 ml-3">Editar Perfil</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
    
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-6">
              <label for="name" class="block text-sm font-medium">Nome</label>
              <input v-model="form.nome" type="text" name="name" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div class="col-span-12 md:col-span-6">
              <label for="email" class="block text-sm font-medium">Email</label>
              <input v-model="form.email" type="text" name="email" id="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            
          </div>
          <div class="grid grid-cols-12 gap-6 mt-3">
            <div v-if="!alterarPassword" class="col-span-6 md:col-span-6 sm:col-span-12 mt-5">
              <button
                @click="alterarPassword = !alterarPassword"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500"
              >
                Alterar password
              </button>
            </div>
            <div class="col-span-6 md:col-span-6 sm:col-span-12" v-if="$store.state.user.operador">
              <p class="text-sm font-medium">Notificações do navegador</p>
              <button v-if="!$store.state.user.notifcacaoNavegador" @click="subscribeToPush" type="button" class="py-2 px-6 border border-transparent  rounded-md bg-green-600 text-white text-sm font-semibold hover:bg-green-700">Ativar Notificações</button>
              <button v-else @click="subscribeCancel" type="button" class="py-2 px-6 border border-transparent rounded-md bg-red-600 text-white text-sm font-semibold hover:bg-red-700">Desativar Notificações</button>
            </div>
          </div>
          <div v-if="alterarPassword" class="grid grid-cols-12 gap-6 mt-3">
            <div class="col-span-12 md:col-span-12">
              <label for="password" class="block text-sm font-medium">Senha</label>
              <input
                autocomplete="new-password"
                v-model="form.password"
                type="password"
                name="password"
                id="password"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-12">
              <label for="password2" class="block text-sm font-medium">Confirme a senha</label>
              <input
                autocomplete="new-password"
                v-model="form.password2"
                type="password"
                name="password2"
                id="password2"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3 text-left sm:px-6">
            <router-link
              :to="`/operadores`"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Voltar
            </router-link>
          </div>
          <div class="px-5 py-3 text-right sm:px-6">
            <button
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alterarPassword: false,
      form: {
        nome: "",
        email: "",
        password: "",
        password2: ""
      }
    };
  },

  methods: {
    async save() {
      if (!this.form.email) {
        return this.$vToastify.error("Digite o email");
      }
      if (this.alterarPassword && !this.form.password) {
        return this.$vToastify.error("Digite a senha");
      }
      if (this.alterarPassword && this.form.password !== this.form.password2) {
        return this.$vToastify.error("Senhas não coincidem");
      }

      const req = await this.$http.put(this.$store.state.user.operador ? `/v1/operador` : "/v1/users", this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/operadores` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
      const rawData = window.atob(base64);
      return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
    },

    async subscribeToPush() {
      const publicVapidKey = process.env.VUE_APP_KEYWEBPUSH;
      if (!("serviceWorker" in navigator)) {
        console.error("Service Worker não suportado!");
        return;
      }

      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.error("Permissão negada pelo usuário.");
        return this.$vToastify.error("Necessário acionar permissão de notificação do navegador");
      }

      // Registra o Service Worker
      const register = await navigator.serviceWorker.ready;

      //  Verifica se já existe uma assinatura
      let existingSubscription = await register.pushManager.getSubscription();

      if (existingSubscription) {
        console.log("🔄 Removendo assinatura antiga...");
        await existingSubscription.unsubscribe();
      }

      // Cria a assinatura no navegador
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey)
      });

      const subscriptionData = subscription.toJSON();

      console.log("🔹 Subscription gerada:", subscriptionData);

      await this.$http.put(`/v1/operador`, {
        notifcacaoNavegador: true,
        endpoint: subscriptionData.endpoint,
        keys: {
          auth: subscriptionData.keys.auth,
          p256dh: subscriptionData.keys.p256dh
        }
      });

      window.location.reload();
    },

    async subscribeCancel() {
      await this.$http.put(`/v1/operador`, {
        notifcacaoNavegador: false,
        endpoint: "",
        keys: {
          auth: "",
          p256dh: ""
        }
      });

      console.log("Notificação Removida");

      window.location.reload();
    }
  },
  async beforeMount() {
    const userReq = await this.$http.post("/v1/users/meLZV2");
    this.form = {
      _id: userReq.data._id,
      email: userReq.data.email,
      nome: userReq.data.nome
    };
  }
};
</script>
